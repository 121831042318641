export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "real_estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie"])},
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr erfahren"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anfragen"])},
        "on_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis auf Anfrage"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieten"])},
        "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf"])},
        "object_rent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zu vermieten"])},
        "object_buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zu verkaufen"])},
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommerzielle Immobilie"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halle"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büro"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrieb"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazin"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksart"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landwirtschaftlicher Grund"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baugrund"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewerbegrundstück"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnimmobilie"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenhaus"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal / Alle"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])}
        }
      },
      "en": {
        "real_estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate"])},
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
        "on_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price on request"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])},
        "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
        "object_rent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Rent a ", _interpolate(_list(0))])},
        "object_buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buy a ", _interpolate(_list(0))])},
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial property"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of land"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agricultural land"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building land"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial land"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential property"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farm"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row house"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any / All"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown / Not specified"])}
        }
      },
      "it": {
        "real_estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie"])},
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri di più"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi"])},
        "on_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo su richiesta"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto"])},
        "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendita"])},
        "object_rent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " da affittare"])},
        "object_buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " da vendere"])},
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobile commerciale"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio commerciale"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capannone"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indifferente"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ufficio"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristorante"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzino"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia di terreno"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno agricolo"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno edificabile"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno commerciale"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobile residenziale"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartamento"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maso"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indifferente"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa a schiera"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non importa / Tutti"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto / Non definito"])}
        }
      }
    }
  })
}
