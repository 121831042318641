<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "field-text",
  emits: ['update:model-value'],
  props: {
    label: {
      type: String
    },
    modelValue: {
      type: String
    },
    placeholder: {
      type: String
    },
    error: {
      type: Boolean
    }
  }
})
</script>

<template>
  <div class="emma-field" v-bind:class="{'emma-field--error': error}">
    <div class="emma-field__label" v-if="label">
      <span>{{ label }}</span>
    </div>
    <div class="emma-field__body">
      <div class="emma-field__wrapper">
        <input class="emma-field-location__input" v-on:input="$emit('update:model-value', $event.target.value)"
               v-bind:value="modelValue" v-bind:placeholder="placeholder">

      </div>
      <div class="emma-field__error-text" v-if="error">
        <span>{{ $t('error_line') }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>

<i18n>
{
  "de": {
    "error_line": "Bitte überprüfe deine Angabe."
  },
  "en": {
    "error_line": "Please check you input."
  },
  "it": {
    "error_line": "Controlla che i dati siano corretti."
  }
}
</i18n>