<template>
  <div class="emma-checkbox" v-on:click="$emit('update:modelValue', !modelValue)" v-bind:class="{'emma-checkbox--error': error}">
    <div class="emma-checkbox__wrapper">
      <div class="emma-checkbox__box" v-bind:class="{'--checked': modelValue}">
      </div>
      <div class="emma-checkbox__label">
        <span>{{ label }}</span>
      </div>
    </div>
    <div class="emma-checkbox__error" v-if="error">
      <span>{{ $t('error_line') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    label: String,
    error: Boolean,
    errorString: String,
    required: Boolean,
  },
  name: "field-checkbox"
}
</script>

<style scoped lang="scss">
.emma-checkbox {
  cursor: pointer;
  user-select: none;
  margin-left: 1em;

  &.emma-checkbox--error {
    .emma-checkbox__box {
      border-color: orangered;
    }
  }

  &__box {
    height: 2em;
    width: 2em;
    margin-right: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #808080;

    &.--checked {

      &::before {
        content: "";
        display: block;
        border-bottom: 2px solid #2f2f2f;
        border-left: 2px solid #2f2f2f;
        width: 1em;
        height: 0.4em;
        transform: rotate(-45deg);
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__label {
    font-size: 1.4em;
    color: #363636;
    flex: 1;
  }

  &__error {
    color: orangered;
    font-size: 1.4em;
    margin-left: 32px;
  }
}
</style>

<i18n>
{
  "de": {
    "error_line": "Deine zustimmung ist verpflichtend."
  },
  "en": {
    "error_line": "Your consent is mandatory."
  },
  "it": {
    "error_line": "Consenso obbligatorio."
  }
}
</i18n>