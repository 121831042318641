

<template>
  <div class="emma-field">
    <div class="emma-field__label">
      <span>{{ label }}</span>
    </div>
    <div class="emma-field__wrapper">
      <select v-on:input="$emit('update:model-value', $event.target.value)" v-bind:value="modelValue">
        <option selected value="" hidden=""></option>
        <template v-if="optgroups.length > 0">
          <optgroup
              v-for="group in optgroups"
              v-bind:key="group"
              v-bind:label="group">
            <option v-for="option in groupOptions(group)" v-bind:key="option.value" v-bind:value="option.value">
              {{ option.label }}
            </option>
          </optgroup>
        </template>
        <template v-else>
          <option v-for="option in options" v-bind:key="option.value" v-bind:value="option.value">
            {{ option.label }}
          </option>
        </template>


      </select>
    </div>

  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "field-select",
  emits: ['update:model-value'],
  props: {
    label: {

    },
    modelValue: {

    },
    options: {
      default: []
    }
  },
  computed: {
    optgroups() {
      return this.options ? this.options.map(x => x.category)[0] !== undefined ? Array.from(new Set(this.options.map(x => x.category))) : [] : [];
    }
  },
  methods: {
    groupOptions(group) {
      return this.options ? this.options.filter(x => x.category === group) : [];
    }
  }
})
</script>

<style scoped>

</style>