export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "error_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfe deine Angabe."])}
      },
      "en": {
        "error_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check you input."])}
      },
      "it": {
        "error_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla che i dati siano corretti."])}
      }
    }
  })
}
