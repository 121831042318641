import {createStore} from "vuex";
import axios from "axios";

export default createStore({
    state: {
        summary: {},
        token: "",
        channel: "",
        request: "",
        apiHost: "",
        overviewPage: "",

        config: {}
    },
    mutations: {
        setSummary(state, data) {
            state.summary = data;
        },
        setAuthData(state, data) {
            state.token = data.token;
            state.channel = data.channel;
            state.request = data.request;
            state.apiHost = data.apiHost;
            state.overviewPage = data.overviewPage;
            state.config = data.config ?? [];
        },
    },
    actions: {
        getConfig(context, payload) {
            return new Promise((resolve, reject) => {
                console.log({payload});
                axios.get(payload).then((r) => {
                    let data = r.data;
                    context.commit('setAuthData', {
                        token: data['token'],
                        channel: data['channel'],
                        request: data['request'],
                        apiHost: data['apiHost'] ?? 'api.emma-office.net',
                        overviewPage: data['overviewPage'] ?? document.querySelector('#emma-widget').getAttribute('data-overview-page'),
                        config: data['config'] ?? []
                    });

                    resolve(data);
                }).catch((e) => {
                    reject(e);
                });
            })
        }
    },
    modules: {}
});