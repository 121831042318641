<template>
    <div class="emma-range">

        <div class="emma-field">
            <div class="emma-field__label">
                <span>{{ label }}</span>
            </div>
            <div class="emma-field__body">
                <div class="emma-field__wrapper"
                    v-bind:class="{'emma-field__wrapper--readonly': !active}"
                >
                    <input class="emma-field-location__input"
                           v-on:input="$emit('update:from', formatOutput($event.target.value))"
                           v-bind:readonly="!active"
                           v-bind:value="active ? formatInput(from) : ''" v-bind:placeholder="$t('from')">
                </div>
            </div>
        </div>

        <div class="emma-range__seperator">
            <span>-</span>
        </div>

        <div class="emma-field">

            <div class="emma-field__body">
                <div class="emma-field__wrapper"
                     v-bind:class="{'emma-field__wrapper--readonly': !active}">
                    <input class="emma-field-location__input"
                           v-on:input="$emit('update:to', formatOutput($event.target.value))"
                           v-bind:readonly="!active"
                           v-bind:value="active ? formatInput(to) : ''" v-bind:placeholder="$t('to')">
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "field-range",
    emits: ["update:from", "update:to"],
    props: {
        label: {
            default: ''
        },
        from: {},
        to: {},
        active: {
            default: true
        }
    },
    methods: {
        formatInput(input) {
            return input == 0 ? "" : new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 10,
                useGrouping: true
            }).format(input)
        },
        formatOutput(output) {
            return output == 0 ? "" : output.replaceAll('.', '');
        }
    }
})
</script>

<style scoped lang="scss">
.emma-range {
  display: flex;
  align-items: end;

  &__seperator {
    width: 3em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 2.4em;
    }
  }

  .emma-field {
    flex: 1;

  }
}
</style>

<i18n>
{
  "de": {
    "from": "Von",
    "to": "Bis"
  },
  "en": {
    "from": "From",
    "to": "To"
  },
  "it": {
    "from": "Da",
    "to": "A"
  }
}
</i18n>