<template>
  <div class="emma-slider">
    <div class="emma-slider__wrapper">
      <splide v-bind:options="splideConfig" v-if="realEstateItems.length > 0">
        <splide-slide
            v-for="item in realEstateItems"
            v-bind:key="item.uuid">
          <real-estate-item
              v-bind:item="item"
              v-bind:key="item.uuid"

              v-on:select-item="selectItem(item, false)"
              v-on:select-request-item="selectItem(item, true)"

              v-bind:link="true"
          ></real-estate-item>
        </splide-slide>
      </splide>
    </div>
  </div>

</template>
<script>
import {defineComponent} from 'vue'
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import {mapState} from "vuex";
import axios from "axios";
import '@splidejs/vue-splide/css/core';
import RealEstateItem from "@/components/real-estate-item.vue";

export default defineComponent({
  name: "SliderApp",
  components: {
    RealEstateItem,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      realEstateItems: [],
      itemsPerPage: 30,
    }
  },
  computed: {
    ...mapState([
      'token', 'channel', 'summary', 'apiHost', 'overviewPage'
    ]),
    params() {
      let params = {
        estate_category: this.objectCategory || 'real_estate',
      };
      params['itemsPerPage'] = 6;
      params['order[createdAt]'] = 'DESC';

      return params;

    },
    splideConfig() {
      return {
        rewind: true,
        type: 'loop',
        perPage: 4,
        gap: '1.5em',
        arrows: false,
        breakpoints: {
          1000: {
            perPage: 3,
          },
          720: {
            perPage: 2,
          },
          480: {
            perPage: 1,
          },
        }
      }
    }
  },
  methods: {
    selectItem(item) {
        location.href = this.overviewPage + '#publication=' + item.uuid;
    },
    async getRealEstateItems() {
      this.loading = true;
      let response = await axios.get(`https://${this.apiHost}/api/v1/channels/${this.channel}/channel_publications`, {
        headers: {
          'authorization': 'Bearer ' + this.token
        },
        params: this.params
      });

      this.realEstateItems = response.data['hydra:member'];
      this.loading = false;
    },
  },
  async created() {
    await this.getRealEstateItems();
  }
})
</script>


<style lang="scss">
#emma-widget {
  font-size: 9px;
}

.emma-slider__wrapper {
  max-width: 140em;
  margin: 0 auto;
  padding: 2em 3em;
}

.splide__pagination {
  margin: 1.5em;
  padding: 0;
}

button.splide__pagination__page {
  all: unset;
  height: 1.2em;
  width: 1.2em;
  background-color: #e7e7e7;
  margin: 0 0.3em;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.splide__pagination {
  margin-top: 1em;
}

button.splide__pagination__page.is-active {
  background-color: color-mix(in srgb, #e7e7e7, black 20%);
}

button.splide__pagination__page:hover {
  background-color: color-mix(in srgb, #e7e7e7, black 30%);
}

</style>