<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "loading-item",
  props: {
    label: {
      type: String
    }
  }
})
</script>

<template>
  <div class="emma-loading__icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 716.62 800.85">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path class="cls-1"
                d="M316.19,800.85A400.58,400.58,0,0,1,14.86,664.11a60,60,0,0,1,90.28-79.05,280.43,280.43,0,1,0,0-369.27,60,60,0,0,1-90.28-79A400.51,400.51,0,1,1,316.19,800.85Z"/>
        </g>
      </g>
    </svg>
  </div>
  <div class="emma-loading__text">

        <span>
          {{ label }}
        </span>
  </div>
</template>

<style scoped lang="scss">
.emma-loading {
  display: flex;
  align-items: center;
  padding: 1.5em 2em;
  color: var(--color-dark);

  &__icon svg {
    margin-left: auto;
    height: 100%;

    * {
      fill: var(--color-dark);
    }
  }

  &__text {
    font-size: 1.8em;
    margin: 0 0.8em;
  }

  &__icon {
    animation: spin 2s linear infinite;
    height: 2.4em;
    width: 2.4em;
    display: flex;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>