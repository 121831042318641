export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "back_to_detail_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Detailansicht"])}
      },
      "en": {
        "back_to_detail_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to overview"])}
      },
      "it": {
        "back_to_detail_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna all'immobile"])}
      }
    }
  })
}
