<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "field-textarea",
    emits: ['update:model-value'],
    props: {
        label: {
            type: String
        },
        modelValue: {
            type: String
        },
        placeholder: {
            type: String
        },
        error: {
            type: Boolean
        }
    }
})
</script>

<template>
    <div class="emma-field" v-bind:class="{'emma-field--error': error}">
        <div class="emma-field__label" v-if="label">
            <span>{{ label }}</span>
        </div>
        <div class="emma-field__body">
            <div class="emma-field__wrapper">
                <textarea class="emma-field-location__input" v-on:input="$emit('update:model-value', $event.target.value)"
                       v-bind:value="modelValue" v-bind:placeholder="placeholder">

                </textarea>

            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>