<script>
import {defineComponent} from 'vue'

export default defineComponent({
  emits: ['delete-selection'],
  name: "field-selected-item",
  props: {
    content: {
      type: String
    }
  }
})
</script>

<template>
  <div class="emma-field">

    <div class="emma-field__wrapper field-selected-item">
      <div class="field-selected-item__content">
        <span>{{ content }}</span>
      </div>
      <div class="field-selected-item__delete" v-on:click="$emit('delete-selection')">
        <span class="emma-icon emma-icon-clear"></span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.field-selected-item {
  display: flex;
  justify-content: space-between;
  background-color: var(--accent-color);
  align-items: center;

  &__delete {
    cursor: pointer;
  }

  &__content {
    padding-left: 2em;

    span {
      font-size: 1.4em;
    }
  }

  &__delete {
    padding-right: 1em;

    .emma-icon {
      font-size: 1.8em;
    }
  }
}
</style>