<template>
    <div class="emma-field">

        <button
                class="emma-field__wrapper emma-choice__wrapper"
                v-bind:class="{'emma-choice--select-1': options && options[0] && this.modelValue === options[0].value, 'emma-choice--select-2': options && options[1] && this.modelValue === options[1].value}">
            <div
                    class="emma-choice__button"
                    v-for="option in options" v-bind:key="option.value"
                    v-on:click="selectChoice(option.value)"
            >
                <span>{{ option.label }}</span>
            </div>
        </button>

    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "field-choice",
    props: {
        modelValue: {},
        options: {}
    },
    methods: {
        selectChoice(choice) {
            this.$emit('update:model-value', choice);
        }
    }
})
</script>

<style scoped lang="scss">
.emma-choice--select-2 {
  &.emma-choice__wrapper::before {
    left: 50%;
    opacity: 1;
  }
}

.emma-choice--select-1 {
  &.emma-choice__wrapper::before {
    opacity: 1;
  }
}

.emma-choice {
  &__wrapper {
    display: flex;
    align-items: center;
    font-size: inherit;
    box-sizing: content-box;
    padding: 0;
    width: 100%;
    font-family: inherit;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      border-radius: var(--border-radius);
      z-index: 5;
      background-color: var(--button-item-selected);
      transition: left 0.3s;
      opacity: 0;
    }
  }

  &__button {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;

    span {
      font-size: 1.4em;
      font-weight: bold;
    }

    &--selected {
      border-right: 1px solid var(--border-color);
      border-radius: var(--border-radius);
      background-color: var(--button-item-selected);

    }
  }
}
</style>