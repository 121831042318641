import { createApp } from 'vue'
import App from './App.vue'
import SliderApp from './SliderApp.vue'
import {createI18n} from "vue-i18n";
import store from "@/store";
const lang = createI18n({
    locale: document.documentElement.lang.substring(0,2) || 'en',
    fallbackLocale: 'en',
});

let configFileUrl = document.querySelector('#emma-widget').getAttribute('data-config');

if(!configFileUrl) {
    store.commit('setAuthData', {
        token: document.querySelector('#emma-widget').getAttribute('data-token'),
        channel: document.querySelector('#emma-widget').getAttribute('data-channel'),
        request: document.querySelector('#emma-widget').getAttribute('data-request'),
        apiHost: document.querySelector('#emma-widget').getAttribute('data-host') ?? 'api.emma-office.net',
        overviewPage: document.querySelector('#emma-widget').getAttribute('data-overview-page'),
    });

    if(document.querySelector('#emma-widget').getAttribute('data-mode') === 'slider') {
        createApp(SliderApp).use(lang).use(store).mount('#emma-widget');
    } else {
        createApp(App).use(lang).use(store).mount('#emma-widget');
    }

} else {

    store.dispatch('getConfig', configFileUrl ).then(() => {
        if(document.querySelector('#emma-widget').getAttribute('data-mode') === 'slider') {
            createApp(SliderApp).use(lang).use(store).mount('#emma-widget');
        } else {
            createApp(App).use(lang).use(store).mount('#emma-widget');
        }
    });

}

