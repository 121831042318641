export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "error_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine zustimmung ist verpflichtend."])}
      },
      "en": {
        "error_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your consent is mandatory."])}
      },
      "it": {
        "error_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenso obbligatorio."])}
      }
    }
  })
}
