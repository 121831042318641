<template>
  <teleport to="body">
    <div class="emma-popup-detail">
      <div class="emma-popup-detail__backdrop" v-on:click="$emit('close-popup')">

      </div>
      <div class="emma-popup-detail__window" ref="window">
        <div class="emma-popup-detail__back" v-on:click="$emit('close-popup')">
          <span class="emma-icon-arrow-back"></span>
          <span>{{ $t('back_to_overview') }}</span>
        </div>

        <div class="emma-popup-detail__title">
          <span>{{ languageString(product.name) }}</span>
        </div>
        <div class="emma-popup-detail__content">
          <div class="emma-popup-detail__general">
            <div class="emma-popup-detail__images">
              <div class="emma-popup-detail__image-nav-arrow" v-on:click="prevImage()">
                <span class="emma-icon-arrow-left"></span>
              </div>
              <div class="emma-popup-detail__image">
                <template v-for="(image, index) in [...product.images, ...product.plans]"
                          v-bind:key="index">
                  <transition>
                    <img
                        v-bind:src="'https://'  +this.apiHost + image.fileLink + '?size=thumbnail-big&aspect_ratio=4x3'"
                        v-if="index === imageIndex"/>
                  </transition>

                </template>

                <div class="emma-popup-detail__image-more"
                     v-on:click="showImagePopup = !showImagePopup">
                  <span>{{ $t('all_images') }}</span>
                </div>

              </div>
              <div class="emma-popup-detail__image-nav-arrow" v-on:click="nextImage()">
                <span class="emma-icon-arrow-right"></span>
              </div>
            </div>
            <div class="emma-popup-detail__properties">
              <div class="emma-popup-detail__property" v-for="icon in icons" v-bind:key="icon.value">
                <div v-bind:class="['emma-icon', icon.icon]"></div>
                <div>
                  <span>{{ icon.value }}</span>
                </div>
              </div>

              <div class="emma-popup-detail__price">
                <span v-if="mainPrice && mainPrice.price">{{
                    formatCurrency(mainPrice.price)
                  }} €</span>
                <span v-else>{{ $t('on_request') }}</span></div>
            </div>
          </div>
          <div class="emma-popup-detail__end">

            <div class="emma-popup-detail__description" v-if="product.description">
              <div class="emma-popup-detail__desc-title">
                <span>{{ $t('description') }}</span>
              </div>
              <div class="emma-popup-detail__desc-content" v-html="languageString(product.description)">
              </div>
            </div>

            <div class="emma-popup-detail__request">
              <div class="emma-popup-detail__request-title">
                <span>{{ $t('non_binding_request') }}</span>
              </div>
              <div class="emma-popup-detail__request-content">
                <request-form v-bind:product="product['@id']"/>
              </div>
            </div>
          </div>
          <div class="emma-popup-detail__infos">
            <div class="emma-popup-detail__info-head">
              <div class="emma-popup-detail__reference-code">
                <span>{{ $t('reference_code', [product.productCustomID]) }}</span>

              </div>
              <div class="emma-popup-detail__short">
                <span>{{
                    $t(mainPrice['@type'] === 'RealEstateRentPrice' ? 'object_rent' : 'object_buy', [
                      $t('estate_type.' + (product['@id'].split('/')[3] !== 'land' ? product['@id'].split('/')[3] : 'usage') + '_type.' +
                          (product.residentialRealEstateType || product.commercialRealEstateType || product.usageType || 'category'))])
                  }}</span>

              </div>
              <div class="emma-popup-detail__location">
                <span class="emma-icon emma-icon-location"></span>
                <span>{{ geoLocation }}</span>
              </div>

            </div>
            <div class="emma-popup-detail__detail-lines">
              <div class="emma-popup-detail__detail-lines-headline">
                <span>{{ $t('object_information') }}</span>
              </div>
              <div class="emma-popup-detail__detail-line" v-for="item in listItems"
                   v-bind:key="item.name">
                <b>{{ item.name }}</b>
                <span>{{ item.value }}</span>
              </div>
            </div>


            <div class="emma-popup-detail__detail-lines" v-if="extraPrices.length > 0">
              <div class="emma-popup-detail__detail-lines-headline">
                <span>{{ $t('extras') }}</span>
              </div>

              <div class="emma-popup-detail__detail-line" v-for="item in extraPrices"
                   v-bind:key="item.name">
                <b>{{ $t('extra_price.' + item.type) }}</b>
                <span>{{
                    formatCurrency(item.price)
                  }} €</span>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <popup-images
        v-if="showImagePopup"
        v-bind:images="[...product.images, ...product.plans]"

        v-on:close-popup="showImagePopup = false"
    />
  </teleport>

</template>

<script>
import index, {mapState} from "vuex";
import RequestForm from "@/components/request-form.vue";
import PopupImages from "@/components/popup-images.vue";

export default {
  name: "popup-detail",
  components: {PopupImages, RequestForm},
  data() {
    return {
      imageIndex: 0,
      showImagePopup: false
    }
  },
  props: {
    item: {
      type: Object
    },
    request: {
      default: false
    }
  },
  computed: {
    ...mapState([
      'token', 'channel', 'summary', 'apiHost'
    ]),
    index() {
      return index
    },
    product() {
      return this.item.realEstate;
    },
    mainPrice() {
      return this.product.pricing.find(x => (x['@type'] === 'RealEstatePurchasePrice' || x['@type'] === 'RealEstateRentPrice'));
    },
    extraPrices() {
      return this.product.pricing.filter(x => ((x['@type'] !== 'RealEstatePurchasePrice' && x['@type'] !== 'RealEstateRentPrice')) && x.price);
    },
    icons() {
      let icons = [];

      if (this.product.rooms && this.product['@type'] === 'CommercialRealEstate')
        icons.push({
          icon: 'emma-icon-office-table',
          value: this.product.rooms
        });

      if (this.product.bedrooms && this.product['@type'] === 'ResidentialRealEstate')
        icons.push({
          icon: 'emma-icon-bed',
          value: this.product.bedrooms
        });

      if (this.product.bathrooms)
        icons.push({
          icon: 'emma-icon-bath',
          value: this.product.bathrooms
        })

      if (this.product.commercialSquareMeters)
        icons.push({
          icon: 'emma-icon-area',
          value: this.formatArea(this.product.commercialSquareMeters) + ' m²'
        })

      return icons;
    },
    geoLocation() {
      let lang = this.$i18n.locale.toUpperCase();
      let geolocation = this.product.geoLocation.localizations?.find((x) => (x.language === lang)) || this.product.geoLocation.localizations?.find((x) => (x.language === 'EN')) || this.product.geoLocation.localizations[0];

      return geolocation.municipality + ", " + (geolocation.province || geolocation.district || geolocation.region);
    },
    listItems() {
      return [
        {
          name: this.$t("list.commercial_surface"),
          value: this.product.commercialSquareMeters ? this.formatArea(this.product.commercialSquareMeters) + ' m²' : null
        },
        {
          name: this.$t("list.net_surface"),
          value: this.product.netSquareMeters ? this.formatArea(this.product.netSquareMeters) + ' m²' : null
        },
        {name: this.$t("list.rooms_bath"), value: this.product.bathrooms ? this.product.bathrooms : null},
        {name: this.$t("list.rooms_bed"), value: this.product.bedrooms ? this.product.bedrooms : null},
        {
          name: this.$t("list.year_built"),
          value: this.product.yearOfConstruction ? this.product.yearOfConstruction : null
        },
        {
          name: this.$t("list.energy_efficiency"),
          value: this.product.energyEfficiency ? this.$te('energy_efficiency.' + this.product.energyEfficiency) ? this.$t('energy_efficiency.' + this.product.energyEfficiency) : this.product.energyEfficiency : null,
        },
        {
          name: this.$t("list.heating_control"),
          value: this.product.heatingControl ? this.$t('heating_control.' + this.product.heatingControl) : null
        },
        {
          name: this.$t("list.heating_energy_source"),
          value: this.product.heatingEnergySource ? this.$t('heating_energy_source.' + this.product.heatingEnergySource) : null
        },
        {
          name: this.$t("list.heating_system"),
          value: this.product.heatingSystem ? this.$t('heating_system.' + this.product.heatingSystem) : null
        },
        {
          name: this.$t("list.condominium_expenses"),
          value: this.product.proportionateOverheadCosts ? this.formatCurrency(this.product.proportionateOverheadCosts) + ' €' : null
        },
        {
          name: this.$t("list.estate_condition"),
          value: this.product.estateCondition ? this.$t('estate_condition.' + this.product.estateCondition) : null
        },
        {name: this.$t("list.has_garage"), value: this.product.garages ? (this.$t('yes')) : null},
        {name: this.$t("list.has_car_parking"), value: this.product.parkingLots ? (this.$t('yes')) : null},
        {name: this.$t("list.has_cellar"), value: this.product.hasCellar ? (this.$t('yes')) : null},
        {name: this.$t("list.has_balcony"), value: this.product.hasBalcony ? (this.$t('yes')) : null},
        {name: this.$t("list.has_terrace"), value: this.product.hasTerrace ? (this.$t('yes')) : null},
        {name: this.$t("list.has_garden"), value: this.product.hasGarden ? (this.$t('yes')) : null},
        {name: this.$t("list.has_pool"), value: this.product.swimmingPool ? (this.$t('yes')) : null},
        {name: this.$t("list.has_elevator"), value: this.product.hasElevator ? (this.$t('yes')) : null},
        {name: this.$t("list.is_furnished"), value: this.product.isFurnished ? (this.$t('yes')) : null},
        {name: this.$t("list.is_rented"), value: this.product.isRented ? (this.$t('yes')) : null},
        {
          name: this.$t("list.floor_meadow"),
          value: this.product.meadow ? this.formatArea(this.product.meadow) + 'm²' : null
        },
        {
          name: this.$t("list.floor_forest"),
          value: this.product.forest ? this.formatArea(this.product.forest) + 'm²' : null
        },
        {name: this.$t("list.reference_code"), value: this.product.productCustomID}
      ].filter(x => x.value !== null);
    },
    totalImages() {
      return this.product.images.length;
    }
  },
  methods: {
    languageString(array) {
      let lang = this.$i18n.locale.toUpperCase();
      return array[lang] ?? array['EN'] ?? array['DE'] ?? Object.values(array)[0];
    },
    formatArea(area) {
      return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0, minimumFractionDigits: 0}).format(area);
    },
    formatCurrency(price) {
      return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 0}).format(price);
    },
    prevImage() {
      this.imageIndex = this.imageIndex - 1;
      if (this.imageIndex < 0)
        this.imageIndex = this.totalImages - 1;
    },
    nextImage() {
      this.imageIndex = this.imageIndex + 1;
      if (this.imageIndex >= this.totalImages)
        this.imageIndex = 0;
    }
  },
  mounted() {
    if (this.request)
      this.$refs.window.scroll(0, 10_000);
  }
}
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.emma-popup-detail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 10;
  align-items: center;
}

.emma-popup-detail__back {
  > {
    :nth-child(1) {
      margin-right: 1em;

      &::before {
        font-size: 2.4em;
      }
    }

    :nth-child(2) {
      font-size: 1.4em;
    }
  }

  padding-bottom: 2em;
  display: flex;
  align-items: center;

  &:hover {
    :nth-child(2) {
      text-decoration: underline;
    }

    cursor: pointer;
  }
}

.emma-popup-detail__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 50%);
}

.emma-popup-detail__content {
  display: grid;
  gap: 1em;
  flex: 1;
  grid-template-columns: 6fr 4fr;
}

.emma-popup-detail__desc-content {
  letter-spacing: 0.45px;
  line-height: 1.5;
  margin-right: 12em;
  margin-bottom: 8em;


  > * {
    font-size: 1.4em;
  }
}

.emma-popup-detail__desc-title {
  span {
    font-size: 1.6em;
    letter-spacing: 0.25px;
  }

  margin-bottom: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
}

.emma-popup-detail__detail-line {
  b, span {
    font-size: 1.4em;
    letter-spacing: 0.45px;
  }

  padding: 0.4em 0.8em;
  margin-bottom: 0.8em;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
}

.emma-popup-detail__detail-lines {
  padding: 0 7em 3em 5em;
}

.emma-popup-detail__detail-lines-headline {
  span {
    font-size: 1.6em;
    letter-spacing: 0.25px;
  }

  padding: 0.2em 0.8em 1.5em;
  text-transform: uppercase;
  font-weight: bold;
}

.emma-popup-detail__general {
  flex: 3;
}

.emma-popup-detail__image {
  flex: 1;
  aspect-ratio: 4 / 3;
  background-color: #D8D8D8;
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    right: 0;
  }
}

.emma-popup-detail__image-nav-arrow {
  span {
    font-size: 3.2em;
  }

  width: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.emma-popup-detail__images {
  display: flex;
}

.emma-popup-detail__info-head {
  padding: 5em 7em 5em 5em;
}

.emma-popup-detail__infos {
  flex: 2;
  background-color: var(--accent-background-color);
  border-radius: var(--border-radius);
  grid-column: 2;
  grid-row: 1 / 3;
}

.emma-popup-detail__location {
  .emma-icon {
    margin-right: 0.4em;

    &::before {
      font-size: 1.6em;
    }
  }

  :nth-child(2) {
    font-size: 1.4em;
    font-weight: 400;
  }

  display: flex;
  align-items: center;
}

.emma-popup-detail__price span {
  font-size: 2em;
}

.emma-popup-detail__properties {
  padding: 3em 7em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emma-popup-detail__property {
  .emma-icon {
    font-size: 4.4em;
    text-align: center;
  }

  :nth-child(2) {
    span {
      font-size: 1.4em;
    }

    margin-top: 0.4em;
    text-align: center;
  }
}

.emma-popup-detail__reference-code span {
  font-size: 1.4em;
}

.emma-popup-detail__request {
  margin-right: 5em;
  margin-bottom: 8em;
}

.emma-popup-detail__request-title {
  padding-bottom: 2em;

  span {
    font-size: 1.6em;
    letter-spacing: 0.25px;
  }

  font-weight: bold;
}

.emma-popup-detail__short {
  span {
    font-size: 2.2em;
    font-weight: bold;
  }

  margin: 0.6em 0 1em;
}

.emma-popup-detail__title {
  span {
    font-size: 2.8em;
    font-weight: 500;
    letter-spacing: 0.35px;
  }

  padding: 1.5em 0 3em;
}

.emma-popup-detail__window {
  max-width: 120em;
  width: 100%;
  background-color: white;
  z-index: 1;
  padding: 4em 8em 8em;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 86vh;
}

.emma-popup-detail__image-more {
  span {
    background-color: white;
    padding: 0.4em 0.6em;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    display: block;
    font-size: 1.3em;
  }

  position: absolute;
  bottom: 1em;
  right: 2em;
  padding: 0 2em 2em 0;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1.5em;
    height: 100%;
    top: 0;
    background-color: white;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
  }

  &::before {
    content: '';
    background-color: white;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    bottom: 0;
    position: absolute;
    right: 2em;
    width: 1.5em;
    height: 1.5em;
  }
}

@media screen and (max-width: 480px) {
  .emma-popup-detail__window {
    margin: 0;
    padding: 0;
  }
  .emma-popup-detail__desc-content {
    margin-right: auto;
  }
  .emma-popup-detail__reference-code {
    margin-bottom: 0.75em;
    font-weight: bold;
  }

  .emma-popup-detail__back > :nth-child(1)::before {
    font-size: 2em;
  }

  .emma-popup-detail__end {
    order: 3;
    padding: 5em 3.5em;
  }

  .emma-popup-detail__image-nav-arrow {
    width: 4em;
  }

  .emma-popup-detail__image-nav-arrow span {
    font-size: 2.4em;
  }

  .emma-popup-detail__properties {
    padding: 3em 4.5em;
  }

  .emma-popup-detail__property .emma-icon {
    font-size: 2.8em;
  }

  .emma-popup-detail__price span {
    font-size: 1.8em;
    font-weight: bold;
    color: #797979;
  }

  .emma-popup-detail__content {
    display: flex;
    flex-direction: column;
  }

  .emma-popup-detail__infos {
    order: 2;
    margin: 0 1.5em;
  }

  .emma-popup-detail__info-head {
    padding: 3em 2.5em 3em 2.5em;
  }

  .emma-popup-detail__detail-lines {
    padding: 0 2.5em 3em 2.5em;
  }

  .emma-popup-detail__request {
    margin-right: 0;
  }
  .emma-popup-detail__short {
    display: none;
  }
  .emma-popup-detail__back {
    padding: 5em 3.5em 2em;
  }

  .emma-popup-detail__title span {
    font-size: 1.8em;
  }

  .emma-popup-detail__title {
    padding: 1em 3.5em 3em;
  }
}
</style>

<i18n>
{
  "de": {
    "real_estate": "Immobilie",
    "back_to_overview": "Zurück zur Übersicht",
    "non_binding_request": "Unverbindlich Anfragen",
    "description": "Beschreibung",
    "learn_more": "mehr erfahren",
    "request": "anfragen",
    "on_request": "Preis auf Anfrage",
    "rent": "Mieten",
    "purchase": "Kauf",
    "object_rent": "{0} zu vermieten",
    "object_buy": "{0} zu verkaufen",
    "extras": "Extras",
    "extra_price": {
      "single_garage": "Einzelgarage",
      "double_garage": "Doppelgarage",
      "garage": "Garage",
      "carport": "Carport",
      "exterior_parking_lot": "Außenstellplatz",
      "cellar": "Keller",
      "garden": "Garten"
    },
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Kommerzielle Immobilie",
        "bar": "Bar",
        "commercial_building": "Gebäude",
        "business": "Geschäft",
        "hall": "Halle",
        "hotel": "Hotel",
        "nd": "Egal",
        "office": "Büro",
        "operation": "Betrieb",
        "other": "Anderes",
        "restaurant": "Restaurant",
        "warehouse": "Magazin"
      },
      "usage_type": {
        "category": "Grundstücksart",
        "agricultural_land": "Landwirtschaftlicher Grund",
        "building_land": "Baugrund",
        "commercial_land": "Gewerbegrundstück",
        "land": "Grundstück"
      },
      "residential_real_estate_type": {
        "category": "Wohnimmobilie",
        "apartment": "Wohnung",
        "residential_building": "Gebäude",
        "farm": "Hof",
        "garage": "Garage",
        "house": "Haus",
        "nd": "Egal",
        "other": "Anderes",
        "row_house": "Reihenhaus",
        "villa": "Villa"
      },
      "unimportant": "Egal / Alle",
      "unknown": "Unbekannt / Nicht Angegeben"
    },
    "yes": "Ja",
    "object_information": "Objektinformationen",
    "reference_code": "Ref Nr. {0}",
    "all_images": "Alle Bilder",
    "list": {
      "commercial_surface": "Handelsfläche",
      "net_surface": "Nettofläche",
      "price": "Preis",
      "estate_condition": "Zustand",
      "rooms_bath": "Badezimmer",
      "rooms_bed": "Schlafzimmer",
      "year_built": "Baujahr",
      "heating_control": "Heizungskontrolle",
      "heating_energy_source": "Heizungsart",
      "heating_system": "Heizungssystem",
      "condominium_expenses": "Kondominumskosten",
      "has_garage": "Hat Garage",
      "has_car_parking": "Hat Stellplatz",
      "has_cellar": "Hat Keller",
      "has_balcony": "Hat Balkon",
      "has_terrace": "Hat Terasse",
      "has_garden": "Hat Garten",
      "has_pool": "Hat Schwimmbad",
      "has_elevator": "Hat Aufzug",
      "is_furnished": "Einrichtung",
      "is_rented": "Ist vermietet",
      "energy_efficiency": "Energiezertifikat",
      "floor_meadow": "Wiesenfläche",
      "floor_forest": "Waldfläche",
      "reference_code": "Referenznummer"
    },
    "energy_efficiency": {
      "none": "Keine",
      "unknown": "Unbekannt / Nicht Angegeben",
      "waiting": "In Ausarbeitung"
    },
    "estate_condition": {
      "good_condition": "Guter Zustand",
      "habitable": "Bewohnbar",
      "nd": "Nicht Angegeben",
      "new": "Neu",
      "renovated": "Rennoviert",
      "to_be_refurbished": "Zu sanieren",
      "to_be_renovated": "Zu rennovieren",
      "unimportant": "Egal / Alle",
      "null": "Unbekannt / Nicht Angegeben",
      "unknown": "Unbekannt / Nicht Angegeben",
      "well_maintained": "Gepflegt"
    },
    "heating_control": {
      "autonomous": "Autonom",
      "central": "Zentral",
      "none": "Keine Heizung"
    },
    "heating_energy_source": {
      "electric": "Elektrisch",
      "gas": "Gas",
      "heatpump": "Wärmepumpe",
      "methan": "Metangas",
      "no_heating": "Keine Heizung",
      "none": "Unbekannt / Nicht Angegeben",
      "oil": "Öl",
      "other": "Anderes",
      "remote": "Fernheizung",
      "solar": "Solarheizung",
      "wood": "Holz/Pellets"
    },
    "heating_system": {
      "air": "Luftwechsler",
      "furnace": "Kachelofen",
      "no_heating": "Keine Heizung",
      "pavement": "Fußboden",
      "radiator": "Heizkörper",
      "unknown": "Unbekannt / Nicht Angegeben"
    }
  },
  "en": {
    "real_estate": "Real Estate",
    "back_to_overview": "Back to overview",
    "non_binding_request": "Non-binding request",
    "description": "Description",
    "learn_more": "Learn more",
    "request": "Request",
    "on_request": "Price on request",
    "rent": "Rent",
    "purchase": "Purchase",
    "object_rent": "Rent a {0}",
    "object_buy": "Buy a {0}",
    "extras": "Extras",
    "extra_price": {
      "single_garage": "Single garage",
      "double_garage": "Double garage",
      "garage": "Garage",
      "carport": "Carport",
      "exterior_parking_lot": "Exterior parking lot",
      "cellar": "Cellar",
      "garden": "Garden"
    },
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Commercial property",
        "bar": "Bar",
        "commercial_building": "Building",
        "business": "Business",
        "hall": "Hall",
        "hotel": "Hotel",
        "office": "Office",
        "operation": "Operation",
        "other": "Other",
        "restaurant": "Restaurant",
        "warehouse": "Warehouse",
        "nd": "Any"
      },
      "usage_type": {
        "category": "Type of land",
        "agricultural_land": "Agricultural land",
        "building_land": "Building land",
        "commercial_land": "Commercial land",
        "land": "Land"
      },
      "residential_real_estate_type": {
        "category": "Residential property",
        "apartment": "Apartment",
        "residential_building": "Building",
        "farm": "Farm",
        "garage": "Garage",
        "house": "House",
        "other": "Other",
        "row_house": "Row house",
        "villa": "Villa",
        "nd": "Any"
      },
      "unimportant": "Any / All",
      "unknown": "Unknown / Not specified"
    },
    "yes": "Yes",
    "object_information": "Object information",
    "reference_code": "Ref No. {0}",
    "all_images": "All images",
    "list": {
      "commercial_surface": "Commercial surface",
      "net_surface": "Net surface",
      "price": "Price",
      "estate_condition": "Condition",
      "rooms_bath": "Bathrooms",
      "rooms_bed": "Bedrooms",
      "year_built": "Year built",
      "heating_control": "Heating control",
      "heating_energy_source": "Heating fuel",
      "heating_system": "Heating system",
      "condominium_expenses": "Condominium expenses",
      "has_garage": "Has garage",
      "has_car_parking": "Has parking space",
      "has_cellar": "Has cellar",
      "has_balcony": "Has balcony",
      "has_terrace": "Has terrace",
      "has_garden": "Has garden",
      "has_pool": "Has pool",
      "has_elevator": "Has elevator",
      "is_furnished": "Furnished",
      "is_rented": "Is rented",
      "energy_efficiency": "Energy certificate",
      "floor_meadow": "Meadow area",
      "floor_forest": "Forest area",
      "reference_code": "Reference number"
    },
    "energy_efficiency": {
      "none": "None",
      "unknown": "Unknown / Not Specified",
      "waiting": "In Progress"
    },
    "estate_condition": {
      "good_condition": "Good Condition",
      "habitable": "Habitable",
      "nd": "Not Specified",
      "new": "New",
      "renovated": "Renovated",
      "to_be_refurbished": "To be Refurbished",
      "to_be_renovated": "To be Renovated",
      "unimportant": "Unimportant / All",
      "null": "Unknown / Not Specified",
      "unknown": "Unknown / Not Specified",
      "well_maintained": "Well Maintained"
    },
    "heating_control": {
      "autonomous": "Autonomous",
      "central": "Central",
      "none": "No Heating"
    },
    "heating_energy_source": {
      "electric": "Electric",
      "gas": "Gas",
      "heatpump": "Heat Pump",
      "methan": "Methane Gas",
      "no_heating": "No Heating",
      "none": "Unknown / Not Specified",
      "oil": "Oil",
      "other": "Other",
      "remote": "Remote Heating",
      "solar": "Solar Heating",
      "wood": "Wood/Pellets"
    },
    "heating_system": {
      "air": "Air Exchanger",
      "furnace": "Tile Stove",
      "no_heating": "No Heating",
      "pavement": "Floor",
      "radiator": "Radiator",
      "unknown": "Unknown / Not Specified"
    }
  },
  "it": {
    "real_estate": "Immobilie",
    "back_to_overview": "Torna all'elenco",
    "non_binding_request": "Richiesta senza impegno",
    "description": "Descrizione",
    "learn_more": "Scopri di più",
    "request": "Richiedi",
    "on_request": "Prezzo su richiesta",
    "rent": "Affitto",
    "purchase": "Vendita",
    "object_rent": "{0} in affitto",
    "object_buy": "{0} in vendita",
    "extras": "Extra",
    "extra_price": {
      "single_garage": "Box auto singolo",
      "double_garage": "Box auto doppio",
      "garage": "Box auto",
      "carport": "Carport",
      "exterior_parking_lot": "Parcheggio esterno",
      "cellar": "Cantina",
      "garden": "Giardino"
    },
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Immobile commerciale",
        "bar": "Bar",
        "commercial_building": "Edificio commerciale",
        "business": "Negozio",
        "hall": "Capannone",
        "hotel": "Hotel",
        "nd": "Indifferente",
        "office": "Ufficio",
        "operation": "Attività",
        "other": "Altro",
        "restaurant": "Ristorante",
        "warehouse": "Magazzino"
      },
      "usage_type": {
        "category": "Tipologia di terreno",
        "agricultural_land": "Terreno agricolo",
        "building_land": "Terreno edificabile",
        "commercial_land": "Terreno commerciale",
        "land": "Terreno"
      },
      "residential_real_estate_type": {
        "category": "Immobile residenziale",
        "apartment": "Appartamento",
        "residential_building": "Edificio",
        "farm": "Maso",
        "garage": "Garage",
        "house": "Casa",
        "nd": "Indifferente",
        "other": "Altro",
        "row_house": "Casa a schiera",
        "villa": "Villa"
      },
      "unimportant": "Non importa / Tutti",
      "unknown": "Sconosciuto / Non definito"
    },
    "yes": "Sì",
    "object_information": "Informazioni",
    "reference_code": "Nr. Rif. {0}",
    "all_images": "Galleria",
    "list": {
      "commercial_surface": "Superficie commerciale",
      "net_surface": "Superficie netta",
      "price": "Prezzo",
      "estate_condition": "Stato",
      "rooms_bath": "Bagno",
      "rooms_bed": "Camere da letto",
      "year_built": "Anno di costruzione",
      "heating_control": "Alimentazione riscaldamento",
      "heating_energy_source": "Tipo riscaldamento",
      "heating_system": "Tipo impianto riscaldamento",
      "condominium_expenses": "Spese mensili",
      "has_garage": "Garage",
      "has_car_parking": "Parcheggio esterno",
      "has_cellar": "Cantina",
      "has_balcony": "Balcone",
      "has_terrace": "Terrazzo",
      "has_garden": "Giardino",
      "has_pool": "Piscina",
      "has_elevator": "Ascensore",
      "is_furnished": "Arredato",
      "is_rented": "Affittato",
      "energy_efficiency": "Classe energetica",
      "floor_meadow": "Superficie prato",
      "floor_forest": "Superficie bosco",
      "reference_code": "Nr. Rif."
    },
    "energy_efficiency": {
      "none": "Nessuna",
      "unknown": "Sconosciuto / Non definita",
      "waiting": "In elaborazione"
    },
    "estate_condition": {
      "good_condition": "Buono stato",
      "habitable": "Abitabile",
      "nd": "Non definito",
      "new": "Nuovo",
      "renovated": "Ristrutturato",
      "to_be_refurbished": "Da risanare",
      "to_be_renovated": "Da ristrutturare",
      "unimportant": "Non importa / tutti",
      "null": "Nessuno / Non definito",
      "unknown": "Sconosciuto / Non definito",
      "well_maintained": "Ben mantenuto"
    },
    "heating_control": {
      "autonomous": "Autonomo",
      "central": "Centralizzato",
      "none": "Nessun riscaldamento"
    },
    "heating_energy_source": {
      "electric": "Elettrico",
      "gas": "Gas",
      "heatpump": "Pompa di calore",
      "methan": "Gas metano",
      "no_heating": "Nessun riscaldamento",
      "none": "Sconosciuto / Non definito",
      "oil": "Gasolio",
      "other": "Altro",
      "remote": "Teleriscaldameno",
      "solar": "Solare",
      "wood": "Legna/pellet"
    },
    "heating_system": {
      "air": "Climatizzatore",
      "furnace": "Stufa",
      "no_heating": "Nessun riscaldamento",
      "pavement": "A pavimento",
      "radiator": "Radiatori",
      "unknown": "Sconosciuto / Non definito"
    }
  }
}
</i18n>