export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich aktzeptiere die Datenschutzerklärung"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfragen"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider gab es einen Fehler beim Senden deiner Anfrage. Gerne kannst du uns Telefonisch oder per E-Mail kontaktieren."])},
        "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage wird gesendet..."])},
        "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Anfrage ist erfolgreich versendet worden!"])}
      },
      "en": {
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the privacy policy"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, there was an error sending your request. You can contact us by phone or email."])},
        "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending request..."])},
        "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been successfully sent!"])}
      },
      "it": {
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto l'informativa sulla privacy"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purtroppo si è verificato un errore nell'invio della richiesta. La invitiamo a contattarci per telefono o via email."])},
        "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invio della richiesta in corso..."])},
        "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta è stata inviata con successo"])}
      }
    }
  })
}
