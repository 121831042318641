<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "real-estate-item",
  emits: ['select-item', 'select-request-item'],
  props: {
    item: {},
    link: {
      default: false
    }
  },
  computed: {
    ...mapState([
      'token', 'channel', 'summary', 'apiHost', 'overviewPage'
    ]),
    product() {
      return this.item.realEstate;
    },
    mainPrice() {
      return this.product.pricing.find(x => (x['@type'] === 'RealEstatePurchasePrice' || x['@type'] === 'RealEstateRentPrice'))
    },
    icons() {
      let icons = [];

      if (this.product.rooms && this.product['@type'] === 'CommercialRealEstate')
        icons.push({
          icon: 'emma-icon-office-table',
          value: this.product.rooms
        });

      if (this.product.bedrooms && this.product['@type'] === 'ResidentialRealEstate')
        icons.push({
          icon: 'emma-icon-bed',
          value: this.product.bedrooms
        });

      if (this.product.bathrooms)
        icons.push({
          icon: 'emma-icon-bath',
          value: this.product.bathrooms
        })

      if (this.product.commercialSquareMeters)
        icons.push({
          icon: 'emma-icon-area',
          value: this.formatArea(this.product.commercialSquareMeters) + ' m²'
        })


      return icons;
    },
    geoLocation() {
      let lang = this.$i18n.locale.toUpperCase();
      let geolocation = this.product.geoLocation.localizations?.find((x) => (x.language === lang)) || this.product.geoLocation.localizations?.find((x) => (x.language === 'EN')) || this.product.geoLocation.localizations[0];

      return geolocation.municipality + ", " + (geolocation.province || geolocation.district || geolocation.region);
    },
    requestLink() {
      return this.overviewPage + '#request=' + this.item.uuid;
    },
    detailLink() {
      return this.overviewPage + '#publication=' + this.item.uuid;
    }
  },
  methods: {
    formatArea(area) {
      return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0, minimumFractionDigits: 0}).format(area);
    },
    formatCurrency(price) {
      return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 0}).format(price);
    }
  }
})
</script>

<template>
  <div class="emma-real-estate-item">
    <div class="emma-real-estate-item__head" v-on:click="$emit('select-item')">
      <div class="emma-real-estate-item__pricing" v-if="mainPrice">
        <span>{{ mainPrice['@type'] === 'RealEstateRentPrice' ? $t('rent') : $t('purchase') }}</span>
      </div>

      <div class="emma-real-estate-item__image">
        <img
            v-bind:src="'https://' + this.apiHost + product.images[0].fileLink + '?size=thumbnail-small&aspect_ratio=4x3'"/>
      </div>

      <div class="emma-real-estate-item__location">
        <span class="emma-icon emma-icon-location"></span>
        <span>{{ geoLocation }}</span>
      </div>

    </div>

    <div class="emma-real-estate-item__body">
      <div class="emma-real-estate-item__title" v-if="mainPrice">
                <span>{{
                    $t(mainPrice['@type'] === 'RealEstateRentPrice' ? 'object_rent' : 'object_buy', [
                      $t('estate_type.' + (product['@id'].split('/')[3] !== 'land' ? product['@id'].split('/')[3] : 'usage') + '_type.' +
                          (product.residentialRealEstateType || product.commercialRealEstateType || product.usageType || 'category'))])
                  }}</span>
      </div>

      <div class="emma-real-estate-item__properties">

        <div class="emma-real-estate-item__property" v-for="icon in icons" v-bind:key="icon.value">
          <div v-bind:class="['emma-icon', icon.icon]"></div>
          <div>
            <span>{{ icon.value }}</span>
          </div>
        </div>

      </div>

      <div class="emma-real-estate-item__price">
        <span v-if="mainPrice && mainPrice.price">{{
            formatCurrency(mainPrice.price)
          }} €</span>
        <span v-else>{{ $t('on_request') }}</span>
      </div>

      <div class="emma-real-estate-item__buttons" v-if="link">
        <a class="emma-real-estate-item__button" v-bind:href="detailLink">
          <span>{{ $t('learn_more') }}</span>
        </a>
        <a class="emma-real-estate-item__button" v-bind:href="requestLink">
          <span>{{ $t('request') }}</span>
        </a>
      </div>
      <div class="emma-real-estate-item__buttons" v-else>
        <button class="emma-real-estate-item__button" v-on:click="$emit('select-item')">
          <span>{{ $t('learn_more') }}</span>
        </button>
        <button class="emma-real-estate-item__button" v-on:click="$emit('select-request-item')">
          <span>{{ $t('request') }}</span>
        </button>
      </div>

    </div>
  </div>

</template>

<style scoped lang="scss">
.emma-real-estate-item {
  overflow: hidden;
  flex-direction: column;
  display: flex;
  height: 100%;

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__head {
    position: relative;
    border-radius: var(--head-border-radius, 1.5em);
    overflow: hidden;
    box-shadow: 0 3px 6px #00000029;
  }

  &_image {
    img {
      width: 100%;
      display: block;
    }

    aspect-ratio: 4 / 3;
    padding-bottom: 1.9em;
    background-color: #d1d1d1;
  }
}

.emma-real-estate-item__image img {
  width: 100%;
  display: block;
}

.emma-real-estate-item__location {
  .emma-icon {
    margin-right: 0.5em;

    &::before {
      font-size: 2em;
    }
  }

  > :nth-child(2) {
    font-size: 1.4em;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    font-weight: bold;
    line-height: 1.2;
  }

  height: 4.2em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 1.5em;
  background-color: var(--color-highlighted);
  color: var(--color-highlighted-text);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: var(--border-radius);
}

.emma-real-estate-item__pricing {
  span {
    font-size: 1.4em;
  }

  position: absolute;
  right: 2em;
  width: 15em;
  text-align: center;
  height: 2.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  background-color: var(--color-dark);
  color: var(--color-dark-text);
  letter-spacing: 0.5px;
}

.emma-real-estate-item__properties {
  display: flex;
  justify-content: space-around;
  margin-bottom: auto;
  padding-bottom: 2em;
}

.emma-real-estate-item__property {
  .emma-icon {
    font-size: 4.4em;
    text-align: center;
  }

  :nth-child(2) {
    span {
      font-size: 1.6em;
    }

    margin-top: 0.4em;
    text-align: center;
  }
}

.emma-real-estate-item__title {
  span {
    font-size: 1.8em;
  }

  padding: 2.6em 0 2.8em;
  text-align: center;
}

.emma-real-estate-item__price {
  padding: 1.5em;
  border-top: 1px solid var(--border-color);

  span {
    font-size: 1.8em;
    font-weight: bold;
  }
}

.emma-real-estate-item__button {
  all: unset;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  height: 3.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-color);
  color: var(--button-color-text);
  transition: background-color 0.3s;
  font-family: inherit;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1em;

  &:hover {
    background-color: color-mix(in srgb, var(--button-color), black 10%);
  }

  span {
    font-size: 1.4em;
  }
}

</style>

<i18n>
{
  "de": {
    "real_estate": "Immobilie",
    "learn_more": "mehr erfahren",
    "request": "anfragen",
    "on_request": "Preis auf Anfrage",
    "rent": "Mieten",
    "purchase": "Kauf",
    "object_rent": "{0} zu vermieten",
    "object_buy": "{0} zu verkaufen",
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Kommerzielle Immobilie",
        "bar": "Bar",
        "commercial_building": "Gebäude",
        "business": "Geschäft",
        "hall": "Halle",
        "hotel": "Hotel",
        "nd": "Egal",
        "office": "Büro",
        "operation": "Betrieb",
        "other": "Anderes",
        "restaurant": "Restaurant",
        "warehouse": "Magazin"
      },
      "usage_type": {
        "category": "Grundstücksart",
        "agricultural_land": "Landwirtschaftlicher Grund",
        "building_land": "Baugrund",
        "commercial_land": "Gewerbegrundstück",
        "land": "Grundstück",
        "nd": "Egal"
      },
      "residential_real_estate_type": {
        "category": "Wohnimmobilie",
        "apartment": "Wohnung",
        "residential_building": "Gebäude",
        "farm": "Hof",
        "garage": "Garage",
        "house": "Haus",
        "nd": "Egal",
        "other": "Anderes",
        "row_house": "Reihenhaus",
        "villa": "Villa"
      },
      "unimportant": "Egal / Alle",
      "unknown": "Unbekannt / Nicht Angegeben"
    }
  },
  "en": {
    "real_estate": "Real Estate",
    "learn_more": "Learn more",
    "request": "Request",
    "on_request": "Price on request",
    "rent": "Rent",
    "purchase": "Purchase",
    "object_rent": "Rent a {0}",
    "object_buy": "Buy a {0}",
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Commercial property",
        "bar": "Bar",
        "commercial_building": "Building",
        "business": "Business",
        "hall": "Hall",
        "hotel": "Hotel",
        "office": "Office",
        "operation": "Operation",
        "other": "Other",
        "restaurant": "Restaurant",
        "warehouse": "Warehouse",
        "nd": "Any"
      },
      "usage_type": {
        "category": "Type of land",
        "agricultural_land": "Agricultural land",
        "building_land": "Building land",
        "commercial_land": "Commercial land",
        "land": "Land",
        "nd": "Any"
      },
      "residential_real_estate_type": {
        "category": "Residential property",
        "apartment": "Apartment",
        "residential_building": "Building",
        "farm": "Farm",
        "garage": "Garage",
        "house": "House",
        "other": "Other",
        "row_house": "Row house",
        "villa": "Villa",
        "nd": "Any"
      },
      "unimportant": "Any / All",
      "unknown": "Unknown / Not specified"
    }
  },
  "it": {
    "real_estate": "Immobilie",
    "learn_more": "Scopri di più",
    "request": "Richiedi",
    "on_request": "Prezzo su richiesta",
    "rent": "Affitto",
    "purchase": "Vendita",
    "object_rent": "{0} da affittare",
    "object_buy": "{0} da vendere",
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Immobile commerciale",
        "bar": "Bar",
        "commercial_building": "Edificio commerciale",
        "business": "Negozio",
        "hall": "Capannone",
        "hotel": "Hotel",
        "nd": "Indifferente",
        "office": "Ufficio",
        "operation": "Attività",
        "other": "Altro",
        "restaurant": "Ristorante",
        "warehouse": "Magazzino"
      },
      "usage_type": {
        "category": "Tipologia di terreno",
        "agricultural_land": "Terreno agricolo",
        "building_land": "Terreno edificabile",
        "commercial_land": "Terreno commerciale",
        "land": "Terreno"
      },
      "residential_real_estate_type": {
        "category": "Immobile residenziale",
        "apartment": "Appartamento",
        "residential_building": "Edificio",
        "farm": "Maso",
        "garage": "Garage",
        "house": "Casa",
        "nd": "Indifferente",
        "other": "Altro",
        "row_house": "Casa a schiera",
        "villa": "Villa"
      },
      "unimportant": "Non importa / Tutti",
      "unknown": "Sconosciuto / Non definito"
    }
  }
}
</i18n>